
import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Demo = () => {
    return (
        <div className="hero__demo">
            <OutboundLink href="https://marketplace.visualstudio.com/items?itemName=ThreadHeap.serverless-ide-vscode" target="blank" alt="ServerlessIDE on Visual Studio Code marketplace">
                <img className="hero__demo__image" src="https://raw.githubusercontent.com/threadheap/serverless-ide-vscode/master/packages/vscode/demo/autocomplete.gif" alt="ServerlessIDE demo" />
            </OutboundLink>
        </div>
    )
}

export default Demo
