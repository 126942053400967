import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Demo from '../components/demo'
import Wrapper from '../components/wrapper'
import Features from '../components/features';
import FeatureItem from '../components/feature-item'
import Callout from '../components/callout'

const IndexPage = () => (
  <Layout title="Home">
    <SEO title="Home" />
    <Hero title="Serverless IDE" description="Edit AWS CloudFormation and Serverless YAML Templates like a Pro" />
    <Demo />
    <Callout title="Try it now" description="Install extesions from VSCode marketplace" link="https://marketplace.visualstudio.com/items?itemName=ThreadHeap.serverless-ide-vscode" />
    <Wrapper>
      <Features>
        <FeatureItem title="Smart autocompletion" description="Boost your productivity with smart autocompletion for your yaml templates and AWS CloudFormation resources." />
        <FeatureItem title="AWS documentation at glance" description="Get easy access to AWS documentation right from the template. Just hover over resources and properties." />
        <FeatureItem title="Go-to definitions and references" description="Navigate in the code with resources links." />
        <FeatureItem title="Validation" description="Serverless IDE can validate templates and references to resources, properties and outputs." />
        <FeatureItem title="AWS SAM and Serverless Framework support" description="Plugin supports major serverless deployment frameworks." />
      </Features>
    </Wrapper>
    <Callout title="Subscribe to our newsletter" description="Subscribe to our newsletter to know first about new features and blog posts" link="https://pavel207.typeform.com/to/jIICkA" />
  </Layout>
);

export default IndexPage
